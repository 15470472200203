import React,{useState} from 'react';
import {Steps} from "antd";
import CompanySettings from './CompanySettings';
import CompanyLocation from './CompanyLocation';
import CompanyKeys from './CompanyKeys';

export default function CompanyDetails({stepNumber}) {
    const [activeStep, setActiveStep] = useState(stepNumber);



    const onFinish = () =>{
      console.info("in onfinish")
    }
    const goBack = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      activeStep === 0 ? setActiveStep(0) : setActiveStep(activeStep - 1);
    };
  
    const goNext = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      activeStep === 3 ? onFinish() : setActiveStep(activeStep + 1);
    };

    const handleCompanySettings = () => {
      goNext();
    }; 

    const handleCompanyLocation = () => {
      goNext();
    }; 

    const handleCompanyKeys = (data) => {
      goNext();
    }; 


    const steps = [
        {
          title: <span className="stepper-title">Company Settings</span>,
          content: <CompanySettings onData={handleCompanySettings}/>,
          icon: 1,
        },
        {
          title: <span className="stepper-title">Company Locations</span>,
          content: <CompanyLocation onData={handleCompanyLocation} goBack={goBack}/>,
          icon: 2,
        },
        {
          title: <span className="stepper-title">Company Keys</span>,
          content: <CompanyKeys  onData={handleCompanyKeys} goBack={goBack}/>,
          icon: 3,
        },
       
      ];

     

      const items = steps.map((item) => ({
        key: item?.title,
        title: item?.title,
      }));

      

  return (
    <>
      <Steps className='company-details-steps' current={activeStep} labelPlacement="vertical" items={items} />
      {steps[activeStep].content}

      
    </>
  )
}
