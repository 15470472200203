import React, { useEffect, useState } from "react";
import { Spin, Card, Table, Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import * as TemplateServices from "../../services/TemplateService"

const Templates = () => {
	const navigate = useNavigate()
	const [loader, setLoader] = useState(false);
	const [templateData, setTemplateData] = useState([])
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [emailData, setEmailData] = useState(null)


	const getTemplates = () => {
		setLoader(true)
		TemplateServices.getTemplates().then((response) => {
			setTemplateData(response?.templates)
			setLoader(false)
		}).catch((error) => {
			setLoader(false)
			console.error({ error })
		})
	}

	useEffect(() => {
		getTemplates()
	}, [])

	const emailTemplatesColumn = [
		{
			title: "S.NO",
			dataIndex: "sno",
			key: "sno",
			render: (text, record, index) => (
				<>
					<span className="template-index">{String(index + 1).padStart(2, "0")}</span>
				</>
			),
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (text, record) => (
				<>
					<span className="template-name">{record?.name}</span>
				</>
			),
		},
		{
			title: "Subject",
			dataIndex: "subject",
			key: "subject",
			render: (text, record) => (
				<>
					<span className="template-subject">{record?.subject}</span>
				</>
			),
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			render: (text, record) => (
				<>
					<div className="actionButtons">
						<Button
							className="btnOutlined me-2"
							style={{ height: "auto" }}
							onClick={() => { setIsModalOpen(true); setEmailData(record) }}
						>
							View
						</Button>
						<Button
							className="btnFilled"
							style={{ height: "auto" }}
							onClick={() => navigate("/edit-template", {
								state: {
									templateId: record?.id
								}
							})}
						>
							Edit
						</Button>
					</div>

				</>
			),
		},
	]

	const handleCancel = () => {
		setIsModalOpen(false)
		setEmailData(null)
	}
	const handleOk = () => {
		setIsModalOpen(false)
		setEmailData(null)
	}
	
	return (
		<>
			{loader ? (
				<div className="spinner">
					<Spin />
				</div>
			) : (
				<div className="mainWrapper">
					<Card className="card contentCard border-0 pb-5">
						<div className="row">
							<div className="col-md-12 mb-4">
								<div className="pageHeader d-flex justify-content-between align-items-center analysisPageHeader">
									<h3>Template List</h3>
								</div>
							</div>
							<div className="table-responsive">
								<Table
									dataSource={templateData}
									columns={emailTemplatesColumn}
									pagination={{
										showSizeChanger: true,
									}}
									sortDirections={["descend", "ascend", "descend"]}
									defaultSortOrder={"descend"}
									showSorterTooltip={false}
								/>
							</div>
						</div>
					</Card>
				</div>
			)}
			<Modal
				title="Email Template"
				className="primaryModal"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={false}
				width={900}
			>
				<div dangerouslySetInnerHTML={{ __html: emailData?.body }} />
			</Modal>
		</>
	);
};

export default Templates;
