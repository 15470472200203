import React, { useEffect, useState } from "react";
import { Button, Spin, Card, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useLocation } from "react-router-dom";
import * as TemplateService from "../../services/TemplateService"
import Swal from "sweetalert2";
import { MdRefresh } from "react-icons/md";
import SummernoteEditor from "../../Helpers/Summernote";

export default function EditTemplate() {
    let { state } = useLocation();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [templateData,setTemplateData] = useState(null)
    const [form] = Form.useForm();
    const [content, setContent] = useState('');

    const handleEditorChange = (content) => {
        setContent(content);
    };
    

 const getTemplateById = (id) =>{
    setLoader(true)
    TemplateService.getTemplateById(id).then((response)=>{
         setTemplateData(response?.templates)
         setContent(response?.templates.body);
         setLoader(false)
    }).catch((error)=>{
        setLoader(false)
       console.error({error})
    })
 }

 useEffect(() => {
    const id = state?.templateId;
    if (id) {
        getTemplateById(id);
    }
}, []);

useEffect(() => {
    if (templateData) {
        form.setFieldsValue({
            subject: templateData?.subject,
            description: templateData?.body,
        });
    }
}, [templateData]);

    const onFinish = (values) => {
        setUpdateLoader(true)

        let formData = {
            "subject": values?.subject,
            "body": values?.description
        }

        TemplateService.updateTemplate(templateData?.id, formData).then((res) => {
            setUpdateLoader(false)
            Swal.fire({
                title: "Updated Successfully!",
                text: `${res?.message}`,
                icon: "success"
            }).then(() => {
                navigate("/templates")
            })
        }).catch((error) => {
            setUpdateLoader(false)
            Swal.fire({
                title: "Failed",
                text: `${error?.message}`,
                icon: "error"
            })
            console.error({ error })
        })
    };

    const onFinishFailed = (error) => {
        console.error({ error });
    };

    return (
        <>
            {loader ? (
                <div className="spinner">
                    <Spin />
                </div>
            ) : (
                <div className="mainWrapper">
                    <Card className="card contentCard border-0 pb-5">
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <div className="pageHeader d-flex justify-content-between align-items-start analysisPageHeader">
                                    <h3>Edit Template</h3>
                                    <Button onClick={() => navigate(-1)} className="btnFilled">
                                        <IoMdArrowRoundBack /> Back
                                    </Button>
                                </div>
                            </div>
                            <div className="edit-email-template-wrapper col-md-12">
                                <Form
                                    form={form}
                                    className="formMain edit-template-form"
                                    name="basic"
                                    title="Register"
                                    initialValues={{
                                        remember: true,
                                        subject: templateData?.subject
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    layout="vertical"
                                >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Item
                                                className="formContent"
                                                label="Subject"
                                                name="subject"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input the subject!",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder="Please Enter Subject"
                                                    className="formControl"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-12">
                                            <Form.Item
                                                className="formContent"
                                                label="Description"
                                                name="description"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input description!",
                                                    },
                                                ]}
                                            >
                                                
                                                {content ?
                                                <SummernoteEditor
                                                    value={content}
                                                    onChange={handleEditorChange}
                                                />:''}
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <div className="formContent">
                                                <Button
                                                    className="btnPrimary w-30"
                                                    type="primary"
                                                    htmlType="submit"
                                                    icon={<MdRefresh/>}
                                                    loading={updateLoader}
                                                    disabled={updateLoader}
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Card>
                </div>
            )}
        </>
    );
}
