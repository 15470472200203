import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import * as AuthService from "../services/AuthService";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Logo from '../assets/images/logo.jpg';
import { MdRefresh } from "react-icons/md";

const ResetPassword = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const token = queryParameters.get("token");
    const [loader, setLoader] = useState(false)


    const navigate = useNavigate();
    const onFinish = (values) => {
        setLoader(false)
        AuthService.resetPassword(values, token)
            .then((response) => {
                Swal.fire({
                    title: "Success",
                    text: "Password reset successfully",
                    icon: "success",
                });
                setLoader(false)
                navigate("/");
            })
            .catch((err) => {
                setLoader(false)
                let message = err.response?.data?.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <div className="authWrapper">
            <div className="innerWrapper d-flex justify-content-center align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="logoSection">
                                <img src={Logo} className="img-fluid" alt="logo" />
                            </div>
                            <div className="card authCard">
                                <div className="card-body">
                                    <div className="formWrapper">
                                        <div className="headingWrapper">
                                            <h2 className="heading">Reset Password?</h2>
                                            <p className="subText">
                                                Stuck? Reset your password here.
                                            </p>
                                        </div>
                                        <Form
                                            className="formMain authForm"
                                            name="basic"
                                            title="Login"
                                            initialValues={{
                                                remember: true,
                                            }}
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            autoComplete="off"
                                            layout="vertical"
                                        >
                                            <Form.Item
                                                name="new_password"
                                                label="New Password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your password!",
                                                    },
                                                    {
                                                        min: 6,
                                                        message:
                                                            "Password must be at least 6 characters long!",
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (
                                                                !value ||
                                                                /[!@#$%^&*(),.?":{}|<>]/.test(value)
                                                            ) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(
                                                                new Error(
                                                                    "Password must contain at least one special character!"
                                                                )
                                                            );
                                                        },
                                                    }),
                                                ]}
                                                hasFeedback
                                            >
                                                <Input.Password
                                                    disabled={loader}
                                                    className="formControl"
                                                    placeholder="Enter new password"
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="confirm_password"
                                                label="Confirm Password"
                                                dependencies={["new_password"]}
                                                hasFeedback
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please confirm your password!",
                                                    },

                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (
                                                                !value ||
                                                                getFieldValue("new_password") === value
                                                            ) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(
                                                                new Error(
                                                                    "The new password that you entered do not match!"
                                                                )
                                                            );
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input.Password
                                                    disabled={loader}
                                                    className="formControl"
                                                    placeholder="Confirm new password"
                                                />
                                            </Form.Item>
                                            <div className="text-center">
                                                <Button
                                                    className="btnPrimary w-50"
                                                    type="primary"
                                                    htmlType="submit"
                                                    disabled={loader}
                                                    icon={<MdRefresh />}
                                                    loading={loader}
                                                >
                                                    Reset
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ResetPassword;
