import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import * as AuthService from "../services/AuthService";
import Swal from "sweetalert2";
import Logo from '../assets/images/logo.jpg';
import { MdRefresh } from "react-icons/md";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [loader,setLoader]  = useState(false)

    const onFinish = (values) => {
        setLoader(true)
        AuthService.forgotPassword(values)
          .then((response) => {
            Swal.fire({
              title: "Success",
              text: "Password reset email sent successfully",
              icon: "success",
            });
            setLoader(false)
            navigate("/");
          })
          .catch((err) => {
            setLoader(false)
            let message = err.response?.data?.message;
            Swal.fire({
              title: "Oops...",
              text: message,
              icon: "error",
            });
            console.log({ err });
          });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <div className="authWrapper">
            <div className="innerWrapper d-flex justify-content-center align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="logoSection">
                                <img src={Logo} className="img-fluid" alt="logo" />
                            </div>
                            <div className="card authCard">
                                <div className="card-body">
                                    <div className="formWrapper">
                                        <div className="headingWrapper">
                                            <h2 className="heading">Forgot Password?</h2>
                                            <p className="subText">Stuck? Reset your password with a click.</p>
                                        </div>
                                        <Form
                                            className="formMain authForm"
                                            name="basic"
                                            title="Login"
                                            initialValues={{
                                                remember: true,
                                            }}
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            autoComplete="off"
                                            layout="vertical"
                                        >
                                            <Form.Item
                                                className="formContent"
                                                label="Email"
                                                name="admin_email"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your email!",
                                                    },
                                                ]}
                                            >
                                                <Input disabled={loader} className="inpt formControl" />
                                            </Form.Item>
                                            <div className="text-center">
                                                <Button className="btnPrimary w-50" type="primary" htmlType="submit" disabled={loader} icon={<MdRefresh/>}
								loading={loader}>
                                                    Submit
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ForgotPassword;
