import React, { useEffect, useState } from "react";
import {
    Table,
    Button,
    Card,
    Upload,
    Input,
    Modal,
    Form,
    Select,
    Spin,
    Switch,
} from "antd";
import moment from "moment";
import * as Employee from "../../services/Employee";
import * as Department from "../../services/Department";
import Swal from "sweetalert2";
import { useNavigate, Link, useLocation } from "react-router-dom";
import * as EmployeeUpload from "../../services/Employee";
import { backendStorgeURL } from "../../helper";
import { UploadIcon, Download, Plus } from "../../constant/icons";
import { MdRefresh } from "react-icons/md";

const { Search } = Input;
const { Option } = Select;

const EmployeeList = () => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const [departments, setDepartments] = useState([]);
    const [loader, setLoader] = useState(true);
    const [updateLoader, setUpdateLoader] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [widgetData, setwidgetData] = useState({});
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [fileList, setFileList] = useState(true);
    const [searchText, setSearchText] = useState("");
    const sampleCSV = backendStorgeURL + "CSV/employee_sample.csv";
    const [selecteddepartment, setSelectedDepartment] = useState([]);
    // const employee = state?.employee ? state?.employee : {};

    const handleDepartmentChange = (value) => {
        setSelectedDepartment(value);
    };

    const onToggle = (checked, record) => {
        let status = checked == true ? "Inactive" : "active";
        let dep = [];
        record?.departments?.forEach((department) => {
            if (!dep.includes(department?.id)) {
                dep.push(department?.id);
            }
        });
        setSelectedDepartment(dep);
        //   setEmployee(record);
        record.departments = dep;
        record.status = status;
        form.setFieldsValue(record);
        onFinish(record);
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (text, record, index) => (
                <>
                    <span>{index + 1}</span>
                </>
            ),
        },
        {
            title: "Name",
            dataIndex: "full_name",
            key: "full_name",
            sorter: (a, b) => a.full_name.localeCompare(b.full_name),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
            title: "Mobile",
            dataIndex: "mobile",
            key: "mobile",
            sorter: (a, b) => a.mobile - b.mobile,
        },
        {
            title: "Departments",
            dataIndex: "departments",
            key: "departments",
            sorter: (a, b) => {
                const aDepartments = a.departments.map((dept) => dept.name).join(" ");
                const bDepartments = b.departments.map((dept) => dept.name).join(" ");
                return aDepartments.localeCompare(bDepartments);
            },
            render: (text, record) => (
                <span>
                    {record.departments.map((department) => department.name).join(" ")}
                </span>
            ),
        },
        {
            title: "Status",
            key: "status",
            render: (text, record) => (
                <Switch
                    defaultChecked={record.status === "active" ? true : false}
                    onChange={() =>
                        onToggle(record.status === "active" ? true : false, record)
                    }
                />
            ),
        },
        {
            title: "Created At",
            key: "created_at",
            sorter: (a, b) => a.created_at.localeCompare(b.created_at),
            render: (text, record) => (
                <span>{moment(record.created_at).format("MMMM Do YYYY")}</span>
            ),
        },
        {
            title: "Updated At",
            key: "updated_at",
            sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
            render: (text, record) => (
                <span>
                    {moment(record.updated_at).isAfter(moment(record.created_at))
                        ? moment(record.updated_at).format("MMMM Do YYYY")
                        : "-"}
                </span>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <div className="actionButtons">
                    <Button
                        onClick={() => handleEdit(record)}
                        className="btnFilled me-2"
                        style={{ height: "auto" }}
                    >
                        Edit
                    </Button>
                    <Button
                        onClick={() => handleDelete(record)}
                        className="btnDanger"
                        style={{ height: "auto" }}
                    >
                        Delete
                    </Button>
                </div>
            ),
        },
    ];

    const handleEdit = (record) => {
        let dep = [];
        record?.departments?.forEach((department) => {
            if (!dep.includes(department?.id)) {
                dep.push(department?.id);
            }
        });
        setSelectedDepartment(dep);
        //   setEmployee(record);
        record.departments = dep;
        form.setFieldsValue(record);
        // Handle edit logic here

        // record?.departments?.map((department) => (
        //     dep.push(department.id)
        // ));
        // navigate("/create-employees", {
        //     state: { employee: record, department: dep },
        // });

        setIsModalOpen(true);
    };

    const handleDelete = (record) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                Employee.deleteEmployee(record.id)
                    .then((response) => {
                        getEmployees();
                        Swal.fire({
                            title: "Success",
                            text: "Employee Deleted",
                            icon: "success",
                        });
                    })
                    .catch((err) => {
                        let message = err.response.data.error;
                        Swal.fire({
                            title: "Oops...",
                            text: message,
                            icon: "error",
                        });
                        console.log({ err });
                    });
            }
        });
        // Handle delete logic here
    };
    let navigate = useNavigate();

    const getEmployees = () => {
        Employee.getEmployees()
            .then((response) => {
                setEmployees(response?.data);
                setwidgetData(response?.widget_data);
                setFilteredEmployees(response?.data);
                setLoader(false);
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };

    useEffect(() => {
        getEmployees();
    }, []);

    const handleUploadFile = ({ file }) => {
        setFileList(true);

        const formData = new FormData();
        if (file) {
            formData.append("csv_file", file);
        } else {
            Swal.fire({
                title: "Oops...",
                text: "Please upload a file.",
                icon: "info",
            }).then(() => {
                setFileList(false);
            });
            return;
        }

        EmployeeUpload.createEmployeeCSV(formData)
            .then((response) => {
                getEmployees();
                Swal.fire({
                    title: "Success",
                    text: "Employee Created Successfully!",
                    icon: "success",
                }).then(() => {
                    setFileList(false);
                });
            })
            .catch((err) => {
                console.info({ err });
                Swal.fire({
                    title: "Oops...",
                    text: err.response.data.error,
                    icon: "error",
                });
                setFileList(false);
                console.log({ err });
            });
    };

    useEffect(() => {
        handleSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    const handleSearch = () => {
        const filteredData = employees.filter((employee) => {
            const nameMatch = employee.full_name
                .toLowerCase()
                .includes(searchText.toLowerCase());
            const emailMatch = employee.email
                .toLowerCase()
                .includes(searchText.toLowerCase());
            return nameMatch || emailMatch;
        });
        setFilteredEmployees(filteredData);
    };

    const showModal = () => {
        form.resetFields();
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        Department.getDepartments()
            .then((response) => {
                setDepartments(response?.data);
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    }, []);

    const onFinish = (values) => {
        setUpdateLoader(true)
        if (form.getFieldValue("id") !== undefined) {
            Employee.updateEmployee(values, form.getFieldValue("id"))
                .then((response) => {
                    setUpdateLoader(false)
                    handleOk();
                    getEmployees();
                    Swal.fire({
                        title: "Employee Updated",
                        icon: "success",
                    });
                })
                .catch((err) => {
                    setUpdateLoader(false)
                    let message = err.response.data.error;
                    Swal.fire({
                        title: "Oops...",
                        text: message,
                        icon: "error",
                    });
                    console.log({ err });
                });
        } else {
            Employee.createEmployee(values)
                .then((response) => {
                    setUpdateLoader(false)
                    handleOk();
                    getEmployees();
                    Swal.fire({
                        title: "Employee Created",
                        icon: "success",
                    });
                })
                .catch((err) => {
                    setUpdateLoader(false)
                    let message = err.response.data.error;
                    Swal.fire({
                        title: "Oops...",
                        text: message,
                        icon: "error",
                    }).then(function () {
                        navigate("/employees");
                    });
                    console.log({ err });
                });
        }

        form.resetFields();
    };

    return (
        <>
            {loader ? (
                <div className="spinner">
                    <Spin />
                </div>
            ) : (
                <div className="mainWrapper">
                    <Card className="card contentCard border-0 pb-5">
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <div className="pageHeader d-flex justify-content-between align-items-centers">
                                    <h3>Employee List</h3>
                                    <div className="buttonsWrap">
                                        <Search
                                            placeholder="Search..."
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                        />
                                        <Upload
                                            className="upload-employee-csv"
                                            customRequest={handleUploadFile}
                                            maxCount={1}
                                            listType="picture"
                                            showUploadList={fileList}
                                        >
                                            <Button
                                                className="upload-csv-button btnOutline me-2"
                                                style={{ border: "1px solid black" }}
                                                type="button"
                                            >
                                                <UploadIcon />
                                                Upload Employees
                                            </Button>
                                        </Upload>
                                        {/* <button type="button" className="btnOutline me-2" onClick={console.log('Clicked')}>
                                    <UploadIcon />
                                    Upload CSV
                                </button> */}
                                        <button type="button" className="btnOutlineFilled me-2">
                                            <Link
                                                className="employee-csv-download"
                                                to={sampleCSV}
                                                download
                                            >
                                                <Download />
                                                Download CSV
                                            </Link>
                                        </button>
                                        <button
                                            type="button"
                                            className="btnFilled"
                                            onClick={showModal}
                                        >
                                            <Plus />
                                            Add Employee
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="innerCol colFirst">
                                    <p className="swotLabel">Total Employees</p>
                                    <h4>{widgetData.total_employees}</h4>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="innerCol colSecond">
                                    <p className="swotLabel">Total Active Employees</p>
                                    <h4>{widgetData.total_active_employees}</h4>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="innerCol colThird">
                                    <p className="swotLabel">Total Swot given</p>
                                    <h4>{widgetData.total_swot_given}</h4>
                                </div>
                            </div>
                            <div className="col-md-12 mt-5">
                                <div className="table-responsive">
                                    <Table
                                        dataSource={filteredEmployees}
                                        columns={columns}
                                        rowKey="id"
                                        loading={loader}
                                        pagination={{
                                            showSizeChanger: true,
                                        }}
                                        sortDirections={["descend", "ascend", "descend"]}
                                        defaultSortOrder={"descend"}
                                        showSorterTooltip={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </Card>
                    <Modal
                        title={
                            form.getFieldValue("id") !== undefined
                                ? "Edit Employee"
                                : "Add Employee"
                        }
                        className="primaryModal"
                        width={767}
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={false}
                    >
                        <Form
                            form={form}
                            onFinish={onFinish}
                            className="addDepartmentForm"
                            layout="vertical"
                        >
                            <div className="formItem">
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item
                                            label="Full Name"
                                            name="full_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input your full name!",
                                                },
                                            ]}
                                        >
                                            <Input disabled={updateLoader} placeholder="Enter Employee Full name" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input a valid email address!",
                                                    type: "email",
                                                },
                                                {
                                                    validator(_, value) {
                                                        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z]*[a-zA-Z0-9]+[a-zA-Z]+\.[a-zA-Z]{2,4}$/;
                                                        if (value && !regex.test(value)) {
                                                            return Promise.reject(
                                                                "Invalid email address. The website name must contain both letters and numbers, and the domain should not exceed 4 characters."
                                                            );
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                maxLength={30}
                                                disabled={updateLoader} placeholder="Enter Employee Email"
                                                onInput={(e) => {
                                                    let value = e.target.value;
                                                    value = value.replace(/['"\s]/g, '');

                                                    const atIndex = value.indexOf('@');
                                                    if (atIndex !== -1) {
                                                        const localPart = value.slice(0, atIndex + 1);
                                                        let domainPart = value.slice(atIndex + 1);
                                                        domainPart = domainPart.replace(/[0-9]/g, '');
                                                        const domainRegex = /\.(com|org|net|co|edu|gov|io|info|biz|[a-zA-Z]{2,4})$/;
                                                        const domainMatch = domainPart.match(domainRegex);
                                                        if (domainMatch) {
                                                            domainPart = domainPart.slice(0, domainMatch.index + domainMatch[0].length);
                                                        }
                                                        const domainSplit = domainPart.split('.');
                                                        if (domainSplit.length > 1 && domainSplit[1].length > 4) {
                                                            domainSplit[1] = domainSplit[1].slice(0, 4);
                                                            domainPart = domainSplit.join('.');
                                                        }

                                                        value = localPart + domainPart;
                                                    }

                                                    e.target.value = value;
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item
                                            label="Mobile"
                                            name="mobile"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input your mobile number!",
                                                },
                                            ]}
                                        >
                                            <Input
                                                disabled={updateLoader}
                                                type="number"
                                                min={1}
                                                minLength={10}
                                                placeholder="Enter Employee Mobile"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item
                                            label="Select Department"
                                            name="departments"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select an Departments!",
                                                },
                                            ]}
                                        >
                                            <Select
                                                disabled={updateLoader}
                                                style={{ width: "100%" }}
                                                placeholder="Select Employee Department"
                                                onChange={handleDepartmentChange}
                                            >
                                                {departments?.map((department) => {
                                                    return (
                                                        <Option key={department.id} value={department.id}>
                                                            {department.name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="submitSection">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="btnFilled"
                                        disabled={updateLoader}
                                        icon={<MdRefresh />}
                                        loading={updateLoader}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </Modal>
                </div>
            )}
        </>
    );
};

export default EmployeeList;
