import React, { useEffect, useState } from "react";
import { Table, Card, Input, Modal, Form, Button, Upload, Spin } from "antd";
import * as Department from "../../services/Department";
import Swal from "sweetalert2";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import { UploadIcon, Download, Plus } from "../../constant/icons";
import { backendStorgeURL } from "../../helper";
import { MdRefresh } from "react-icons/md";

const { Search } = Input;

const DepartmentList = () => {
    let navigate = useNavigate();
    const [departments, setDepartments] = useState([]);
    const [widgetData, setwidgetData] = useState({});
    const [searchText, setSearchText] = useState("");
    const [loader, setLoader] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileList, setFileList] = useState(true);
    const [updateLoader, setUpdateLoader] = useState(false)
    const [form] = Form.useForm();
    const sampleCSV = backendStorgeURL + "CSV/department_sample.csv";

    const showModal = () => {
        form.resetFields();
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            // sorter: (a, b) => a.id - b.id,
            render: (text, record, index) => (
                <>
                    <span>{index + 1}</span>
                </>
            ),
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: "Created At",
            key: "created_at",
            sorter: (a, b) => a.created_at.localeCompare(b.created_at),
            render: (text, record) => (
                <span>{moment(record.created_at).format("MMMM Do YYYY")}</span>
            ),
        },
        {
            title: "Updated At",
            key: "updated_at",
            sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
            render: (text, record) => (
                <span>
                    {moment(record.updated_at).isAfter(moment(record.created_at))
                        ? moment(record.updated_at).format("MMMM Do YYYY")
                        : "-"}
                </span>
            ),
        },
        {
            title: "Action",
            key: "actions",
            render: (text, record) => (
                <div className="actionButtons">
                    <button
                        className="btnFilled me-2"
                        style={{ height: "auto" }}
                        onClick={() => handleEdit(record)}
                    >
                        Edit
                    </button>
                    <button
                        className="btnDanger"
                        style={{ height: "auto" }}
                        onClick={() => handleDelete(record)}
                    >
                        Delete
                    </button>
                </div>
            ),
        },
    ];

    const handleEdit = (record) => {
        form.setFieldsValue(record);
        setIsModalOpen(true);
    };

    const handleDelete = (record) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                Department.deleteDepartment(record.id)
                    .then((response) => {
                        getDepartments();
                        Swal.fire({
                            title: "Success",
                            text: "Department Deleted",
                            icon: "success",
                        });
                    })
                    .catch((err) => {
                        let message = err.response.data.error;
                        Swal.fire({
                            title: "Oops...",
                            text: message,
                            icon: "error",
                        });
                        console.log({ err });
                    });
            }
        });
    };

    const getDepartments = () => {
        Department.getDepartments()
            .then((response) => {
                setDepartments(response?.data);
                setwidgetData(response?.widget_data);
                setLoader(false);
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };

    useEffect(() => {
        getDepartments();
    }, []);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredDepartments = departments.filter((department) =>
        department.name.toLowerCase().includes(searchText.toLowerCase())
    );

    const handleUploadFile = ({ file }) => {
        setFileList(true);

        const formData = new FormData();
        if (file) {
            formData.append("csv_file", file);
        } else {
            Swal.fire({
                title: "Oops...",
                text: "Please upload a file.",
                icon: "info",
            }).then(() => {
                setFileList(false);
            });
            return;
        }

        Department.improtDepartmentCSV(formData)
            .then((response) => {
                getDepartments();
                Swal.fire({
                    title: "Success",
                    text: "Department Created Successfully!",
                    icon: "success",
                }).then(() => {
                    setFileList(false);
                });
            })
            .catch((err) => {
                Swal.fire({
                    title: "Oops...",
                    text: err.response.data.error,
                    icon: "error",
                });
                setFileList(false);
                console.log({ err });
            });
    };

    const onFinish = (values) => {
        setUpdateLoader(true)
        if (form.getFieldValue("id") !== undefined) {
            Department.updateDepartment(values, form.getFieldValue("id"))
                .then((response) => {
                    setUpdateLoader(false)
                    getDepartments();
                    handleOk();
                    Swal.fire({
                        title: "Department Updated",
                        icon: "success",
                    }).then(function () {
                        setUpdateLoader(false)
                        navigate("/department");
                    });
                })
                .catch((err) => {
                    setUpdateLoader(false)
                    let message = err.response.data.error.name;
                    Swal.fire({
                        title: "Oops...",
                        text: message,
                        icon: "error",
                    });
                    console.log({ err });
                });
        } else {
            Department.createDepartmnet(values)
                .then((response) => {
                    setUpdateLoader(false)
                    getDepartments();
                    handleOk();
                    Swal.fire({
                        title: "Department Created",
                        icon: "success",
                    }).then(function () {
                        navigate("/department");
                    });
                })
                .catch((err) => {
                    setUpdateLoader(false)
                    let message = err.response.data.error.name;
                    Swal.fire({
                        title: "Oops...",
                        text: message,
                        icon: "error",
                    });
                    console.log({ err });
                });
        }

        form.resetFields();
    };

    return (
        <>
            {loader ? (
                <div className="spinner">
                    <Spin />
                </div>
            ) : (
                <div className="mainWrapper">
                    <Card className="card contentCard border-0 pb-5">
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <div className="pageHeader d-flex justify-content-between align-items-centers">
                                    <h3>Departments</h3>
                                    <div className="buttonsWrap">
                                        <Search placeholder="Search..." onChange={handleSearch} />
                                        <Upload
                                            className="upload-employee-csv"
                                            customRequest={handleUploadFile}
                                            maxCount={1}
                                            listType="picture"
                                            showUploadList={fileList}
                                        >
                                            <Button
                                                className="upload-csv-button btnOutline me-2"
                                                style={{ border: "1px solid black" }}
                                                type="button"
                                            >
                                                <UploadIcon />
                                                Upload Departments
                                            </Button>
                                        </Upload>
                                        <button
                                            type="button"
                                            className="btnOutlineFilled me-2"
                                            onClick={console.log("Clicked")}
                                        >
                                            <Link
                                                className="employee-csv-download"
                                                to={sampleCSV}
                                                download
                                            >
                                                <Download />
                                                Download CSV
                                            </Link>
                                        </button>
                                        <button
                                            type="button"
                                            className="btnFilled"
                                            onClick={showModal}
                                        >
                                            <Plus />
                                            Add Department
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="innerCol colFirst">
                                    <p className="swotLabel">Total Department</p>
                                    <h4>{widgetData.total_departments}</h4>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="innerCol colSecond">
                                    <p className="swotLabel">
                                        Max Employees - {widgetData.max_employees}
                                    </p>
                                    <h4>Name - {widgetData.max_employees_dep_name}</h4>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="innerCol colThird">
                                    <p className="swotLabel">
                                        Least Employees -{widgetData.min_employees}
                                    </p>
                                    <h4>Name - {widgetData.min_employees_dep_name}</h4>
                                </div>
                            </div>
                            <div className="col-md-12 mt-5">
                                <div className="table-responsive">
                                    <Table
                                        dataSource={filteredDepartments}
                                        columns={columns}
                                        rowKey="id"
                                        loading={loader}
                                        pagination={{
                                            showSizeChanger: true,
                                        }}
                                        sortDirections={["descend", "ascend", "descend"]}
                                        defaultSortOrder={"descend"}
                                    />
                                </div>
                            </div>
                        </div>
                    </Card>
                    <Modal
                        title={
                            form.getFieldValue("id") !== undefined
                                ? "Update Department"
                                : "Create Department"
                        }
                        className="primaryModal"
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={false}
                    >
                        <Form form={form} onFinish={onFinish} className="addDepartmentForm">
                            <div className="formItem">
                                <label>Department Name</label>
                                <Form.Item
                                    // initialValue={department.name ? department.name : ""}
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input the department name!",
                                        },
                                    ]}
                                >
                                    <Input disabled={updateLoader} placeholder="Enter Department Name" />
                                </Form.Item>
                                <div className="submitSection">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="btnFilled"
                                        disabled={updateLoader}
                                        icon={<MdRefresh />}
                                        loading={updateLoader}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </Modal>
                </div>
            )}
        </>
    );
};

export default DepartmentList;
