import React, { useEffect, useState } from "react";
import { Table, Button, Card, theme, Upload, Spin } from "antd";
import * as SwotService from "../../services/SwotService";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { backendStorgeURL } from "../../helper";
import { UploadIcon, Download } from "../../constant/icons";
import moment from "moment";

const Swots = () => {
  const [loader, setLoader] = useState(true);
  const [swots, setSwots] = useState([]);
  const [fileList, setFileList] = useState(true);
  const [widgetData, setwidgetData] = useState({});
  let navigate = useNavigate();
  const is_ai_service = localStorage.getItem("is_ai_service");
  const [current, setCurrent] = useState({
    current: 1,
    pageSize: "10",
    last_page: 0,
    total: 10,
  });

  const onChange = (page, filters, sorter) => {
    setCurrent(page);
    getSwots(page);
  };

  const handleUploadFile = ({ file }) => {
    setFileList(true);

    const formData = new FormData();
    if (file) {
      formData.append("csv_file", file);
    } else {
      Swal.fire({
        title: "Oops...",
        text: "Please upload a file.",
        icon: "info",
      }).then(() => {
        setFileList(false);
      });
      return;
    }

    SwotService.improtSwotResponseCSV(formData)
      .then((response) => {
        getSwots();
        Swal.fire({
          title: "Success",
          text: "Employee Created Successfully!",
          icon: "success",
        }).then(() => {
          setFileList(false);
        });
      })
      .catch((err) => {
        console.info({ err });
        Swal.fire({
          title: "Oops...",
          text: err.response.data.error,
          icon: "error",
        });
        setFileList(false);
        console.log({ err });
      });
  };

  const sampleCSV = backendStorgeURL + "CSV/swot_response_sample.csv";

  const getSwots = (page) => {
    SwotService.getSwotSummary(page)
      .then((response) => {
        setSwots(response?.swots);
        setwidgetData(response?.widget_data);
        setCurrent(response.pagination);
        setLoader(false);
      })
      .catch((err) => {
        let message = err.response.data.error;
        Swal.fire({
          title: "Oops...",
          text: message,
          icon: "error",
        });
        console.log({ err });
      });
  };
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const columns = [
    {
      title: "S.NO",
      dataIndex: "S.NO",
      key: "S.NO",
      render: (text, record, index) => (
        <>
          <span>{current.current * 10 + index - 9}</span>
        </>
      ),
    },
    {
      title: "Swot Name",
      dataIndex: "swot_name",
      key: "swot_name",
      sorter: (a, b) => a.swot_name.localeCompare(b.swot_name),
    },
    {
      title: "Sent To ",
      dataIndex: "recipient_count",
      key: "recipient_count",
    },
    {
      title: "Filled By",
      dataIndex: "response_count",
      key: "response_count",
    },
    {
      title: "Response Percentage",
      key: "response_percentage",
      render: (text, record) => (
        <span>
          {record
            .response_count === 0 ? "0.00" : (
              (record.response_count / record.recipient_count) * 100
            )
              .toFixed(2)}{" "}
          %
        </span>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (text, record) => (
        <span>
          {moment(record.expiry_time).isAfter(moment())
            ? "Active"
            : "Inactive"}
        </span>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          {record.response_count > 0 ? (
            <>
              {" "}
              <button
                className="btnFilled me-2"
                onClick={() =>
                  navigate(`/swot-response`, {
                    state: { data: record },
                  })
                }
              >
                View
              </button>
              {
                is_ai_service === "1" ? <button
                  className="btnOutlineFilled"
                  onClick={() =>
                    navigate(`/swot_analysis`, {
                      state: { data: record },
                    })
                  }
                >
                  Analyse Swot
                </button> : <button disabled
                  title="AI Service is not enabled"
                  className="btnOutlineFilled"
                  onClick={() =>
                    navigate(`/swot_analysis`, {
                      state: { data: record },
                    })
                  }
                >
                  Analyse Swot
                </button>
              }

            </>
          ) : (
            " Not Filled"
          )}
        </span>
      ),
    },
  ];

  useEffect(() => {
    getSwots({});
  }, []);
  return (
    <>
      {loader ? (
        <div className="spinner">
          <Spin />
        </div>
      ) : (
        <div className="mainWrapper">
          <Card className="card contentCard border-0 pb-5">
            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="pageHeader d-flex justify-content-between align-items-center analysisPageHeader">
                  <h3>Swot List</h3>
                  <div className="buttonsWrap">
                    <Upload
                      className="upload-employee-csv"
                      customRequest={handleUploadFile}
                      maxCount={1}
                      listType="picture"
                      showUploadList={fileList}
                    >
                      <Button
                        className="upload-csv-button btnOutline me-2"
                        style={{ border: "1px solid black" }}
                        type="button"
                      >
                        <UploadIcon />
                        Upload SWOT Response
                      </Button>
                    </Upload>
                    <button type="button" className="btnOutlineFilled me-2">
                      <Link
                        className="employee-csv-download"
                        to={sampleCSV}
                        download
                      >
                        <Download />
                        Download Sample CSV
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-4">
                <div className="innerCol colFirst">
                  <p className="swotLabel">Total Swots</p>
                  <h4>{widgetData?.swot}</h4>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-4">
                <div className="innerCol colSecond">
                  <p className="swotLabel">Total Send To</p>
                  <h4>{widgetData?.totalEmployee}</h4>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-4">
                <div className="innerCol colThird">
                  <p className="swotLabel">Total Field By</p>
                  <h4>{widgetData?.totalResponse}</h4>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="innerCol colFourth">
                  <p className="swotLabel">Total Response Percentage</p>
                  <h4>
                    {widgetData.totalResponse === 0 ? '0%' : (
                      ((widgetData.totalResponse / widgetData.totalEmployee) * 100).toFixed(2) + '%'
                    )}

                  </h4>
                </div>
              </div>
              <div className="col-md-12 mt-5">
                <div className="table-responsive">
                  <Table
                    dataSource={swots}
                    columns={columns}
                    pagination={{
                      pageSize: current?.pageSize ? current.pageSize : "10",
                      total: current?.total ? current.total : 10,
                      pageSizeOptions: [10, 20, 50, 100],
                      locale: "",
                      showSizeChanger: true,
                    }}
                    sortDirections={["descend", "ascend", "descend"]}
                    defaultSortOrder={"descend"}
                    rowKey="id"
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      )}
    </>

  );
};

export default Swots;
