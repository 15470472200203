import Http from "../Http";
import { backendUrl } from "../helper";

export const getTemplates = () => {
    return new Promise((resolve, reject) => {
      Http.get(backendUrl + "templates")
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  export const updateTemplate = (id,value) => {
    return new Promise((resolve, reject) => {
      Http.put(backendUrl + `templates/${id}`,value)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  export const getTemplateById = (id) => {
    return new Promise((resolve, reject) => {
      Http.get(backendUrl + `templates/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };