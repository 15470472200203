import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Spin } from "antd";
import Swal from "sweetalert2";
import * as AuthService from "../../../services/AuthService";
import { MdRefresh } from "react-icons/md";

export default function CompanyLocation({ onData, goBack }) {
	const [form] = Form.useForm();

	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);
	const [states, setStates] = useState([]);
	const [timeZones, setTimeZones] = useState([]);
	const [countryCode, setCountryCode] = useState("");
	const [loader, setLoader] = useState(false)


	const handleCountryChange = (value, option) => {
		setStates([]);
		form.setFieldsValue({ state: "" });
		form.setFieldsValue({ city: "" });
		form.setFieldsValue({ timezone: "" });
		setCountryCode(option.key);
		getState(option.key);
		getTimeZone(option.key);
	};

	const handleStateChange = (value, option) => {
		getCity(option.key, countryCode);
		form.setFieldsValue({ city: "" });
	};

	const { Option } = Select;

	const onFinish = (values) => {
		setLoader(true)
		const formData = new FormData();
		formData.append("company_address", values?.company_address)
		formData.append("country", values?.country)
		formData.append("state", values?.state)
		formData.append("city", values?.city)
		formData.append("zip", values?.zip_code)
		formData.append("timezone", values?.time_zone)

		AuthService.updateCompanyDetails(formData).then((response) => {
			setLoader(false)
			onData()
		}).catch((error) => {
			setLoader(false)
			console.error({ error })
		})

	}

	const onFinishFailed = (errorInfo) => {
		console.error("Failed:", errorInfo);
	};

	const getCountry = () => {
		AuthService.getCountry()
			.then((response) => {
				setCountries(response);
			})
			.catch((err) => {
				let message = err.response.data.error;
				Swal.fire({
					title: "Oops...",
					text: message,
					icon: "error",
				});
				console.log({ err });
			});
	};

	const getState = (country_code) => {
		AuthService.getState(country_code)
			.then((response) => {
				setStates(response);
			})
			.catch((err) => {
				let message = err.response.data.error;
				Swal.fire({
					title: "Oops...",
					text: message,
					icon: "error",
				});
				console.log({ err });
			});
	};

	const getCity = (state_code, country_code) => {
		AuthService.getCity(state_code, country_code)
			.then((response) => {
				setCities(response);
			})
			.catch((err) => {
				let message = err.response?.data?.error;
				Swal.fire({
					title: "Oops...",
					text: message,
					icon: "error",
				});
				console.log({ err });
			});
	};

	const getTimeZone = (country_code) => {
		AuthService.getTimeZone(country_code)
			.then((response) => {
				setTimeZones(response);
			})
			.catch((err) => {
				let message = err.response.data.error;
				Swal.fire({
					title: "Oops...",
					text: message,
					icon: "error",
				});
				console.log({ err });
			});
	};

	useEffect(() => {
		getCountry();
	}, []);
	return (
		<div>
			<Form
				form={form}
				className="formMain authForm"
				name="basic"
				title="Register"
				initialValues={{
					remember: true,
				}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
				layout="vertical"
			>
				<div className="row">
					<div className="col-md-6">
						<Form.Item
							label="Company Address"
							name="company_address"
							rules={[
								{
									required: true,
									message: "Please input your company address!",
								},
							]}
						>
							<Input
								placeholder="Please Enter Company Address"
							/>
						</Form.Item>
					</div>
					<div className="col-md-6">
						<Form.Item
							label="Country"
							name="country"
							rules={[
								{
									required: true,
									message: "Please select country!",
								},
							]}
						>
							<Select
								placeholder="Select country"
								className="formSelect"
								onChange={handleCountryChange}
								showSearch
								filterOption={(input, option) =>
									option.children?.toLowerCase()?.includes(input?.toLowerCase())
								}
							>
								{countries.length > 0 ? (
									countries.map((country) => (
										<Option
											key={country.country_code}
											value={country.country_name}
										>
											{country.country_name}
										</Option>
									))
								) : (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<Spin />
									</div>
								)}
							</Select>
						</Form.Item>
					</div>
					<div className="col-md-6">
						<Form.Item
							label="State"
							name="state"
							rules={[
								{
									required: true,
									message: "Please select state!",
								},
							]}
						>
							<Select
								className="formSelect"
								placeholder="Select state"
								onChange={handleStateChange}
								disabled={countryCode === "" ? true : false}
								showSearch

							>
								{states.length > 0 ? (
									states?.map((state) => {
										return (
											<Option
												key={state.state_code}
												value={state.state_name}
											>
												{state.state_name}
											</Option>
										);
									})
								) : (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											width: "100%",
										}}
									>
										<Spin />
									</div>
								)}
							</Select>
						</Form.Item>
					</div>
					<div className="col-md-6">
						<Form.Item
							label="City"
							name="city"

						>
							<Select
								placeholder="Select city"
								className="formSelect"
								showSearch={true}
								disabled={countryCode === "" ? true : false}
							>
								{cities.length > 0 ? (
									cities?.map((city) => {
										return (
											<Option
												key={city.city_code}
												value={city.city_name}
											>
												{city.city_name}
											</Option>
										);
									})
								) : (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											width: "100%",
										}}
									>
										<Spin />
									</div>
								)}
							</Select>
						</Form.Item>
					</div>

					<div className="col-md-6">
						<Form.Item
							label="Zip Code"
							name="zip_code"
							rules={[
								{
									required: true,
									message: "Please input zip code!",
								},
							]}
						>
							<Input
								min={1}
								className="formControl"
								placeholder="Zip Code"
								maxLength={6}
								onKeyPress={(e) => {
									if (!/[0-9]/.test(e.key)) {
										e.preventDefault();
									}
								}}
								onPaste={(e) => {
									const paste = (
										e.clipboardData || window.clipboardData
									).getData("text");
									if (!/^[0-9]+$/.test(paste)) {
										e.preventDefault();
									}
								}}
							/>
						</Form.Item>
					</div>
					<div className="col-md-6">
						<Form.Item
							label="Time Zone"
							name="time_zone"
							rules={[
								{
									required: true,
									message: "Please select time zone!",
								},
							]}
						>
							<Select
								className="formSelect"
								placeholder="Select Timezone"
							>
								{timeZones?.map((time_zone) => {
									return (
										<Option
											key={time_zone.id}
											value={time_zone.time_zone}
										>
											{time_zone.time_zone}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
					</div>

				</div>

				<div className="row">
					<div className="col-md-6 text-center">

						<div className="formContent">
							<Button
								className="btnPrimary w-50"
								type="primary"
								onClick={goBack}
							>
								Back
							</Button>
						</div>
					</div>
					<div className="col-md-6 text-center">

						<div className="formContent">
							<Button
								className="btnPrimary w-50"
								type="primary"
								htmlType="submit"
								icon={<MdRefresh />}
								loading={loader}
								disabled={loader}
							>
								Next
							</Button>
						</div>
					</div>
				</div>

			</Form>
		</div>
	)
}
