import React, { useEffect, useState } from "react";
import { Table, Button, Input, Select, Card, Spin, Switch } from "antd";
import moment from "moment";
import * as SwotService from "../../services/SwotService";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
    Plus
} from '../../constant/icons';

const SwotSchedulesList = () => {
    const { Search } = Input;
    const [loader, setLoader] = useState(true);
    const [widgetData, setwidgetData] = useState({});
    const [filter, setFilter] = useState([
        { filter_key: "name", filter_value: "" },
        { filter_key: "send_frequency", filter_value: "" },
    ]);
    const [current, setCurrent] = useState({
        current: 1,
        pageSize: "10",
        last_page: 0,
        total: 10,
        sort: "DESC",
        sortBy: "created_at",
    });

    const onToggle = (checked, record) => {
        let status = checked === true ? 0 : 1;
        record.type = "status_update";
        record.status = status
        updateSchedule(record)

    };

    const updateSchedule = (values) => {
        SwotService.updatescheduleSwot(values, values.id)
            .then((response) => {
                Swal.fire({
                    title: "Swot Updated",
                    icon: "success",
                })
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };


    const filterOption = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const handleSearch = (e) => {
        const value = e.target.value;
        setFilter((prevFilter) =>
            prevFilter.map((f) =>
                f.filter_key === "name" ? { ...f, filter_value: value } : f
            )
        );
    };

    const onChangeFrequency = (value) => {
        getSwotSchedules(
            {},
            filter[0].filter_value === "" && value === ""
                ? []
                : filter[0].filter_value === ""
                    ? [{ filter_key: "send_frequency", filter_value: value.toString() }]
                    :
                    filter[0].filter_value !== "" && value === "" ? [{
                        filter_key: "name",
                        filter_value: filter[0].filter_value,
                    }] :
                        [
                            {
                                filter_key: "name",
                                filter_value: filter[0].filter_value,
                            },
                            { filter_key: "send_frequency", filter_value: value.toString() },
                        ]
        );
        setFilter((prevFilter) =>
            prevFilter.map((f) =>
                f.filter_key === "send_frequency"
                    ? { ...f, filter_value: value.toString() }
                    : f
            )
        );
    };


    const dataBaseFields = { ascend: "ASC", descend: "DESC" };
    const columns = [
        {
            title: "S.NO",
            dataIndex: "S.NO",
            key: "S.NO",
            render: (text, record, index) => (
                <>
                    <span>{current.current * 10 + index - 9}</span>
                </>
            ),
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: "Send Frequency",
            dataIndex: "send_frequency",
            key: "send_frequency",
            sorter: (a, b) => a.send_frequency.localeCompare(b.send_frequency),
        },
        {
            title: "Send Via",
            dataIndex: "send_via",
            key: "send_via",
        },
        {
            title: "Send Time",
            key: "send_time",
            render: (text, record) => <span>{record.send_date}</span>,
        },
        {
            title: "Day",
            dataIndex: "send_day_of_week",
            key: "send_day_of_week",
        },
        {
            title: "Expiry Time",
            key: "expiry_time",
            render: (text, record) => (
                <span>
                    { moment(record.expiry_time).format('MMMM Do YYYY hh:mm A')}
                </span>
            ),
        },
        //   {
        //     title: "Recipient",
        //     dataIndex: "recipient",
        //     key: "recipient",
        //   },
        {
            title: "Created At",
            key: "created_at",
            sorter: (a, b) => a.created_at.localeCompare(b.created_at),
            render: (text, record) => (
                <span>{moment(record.created_at).format("MMMM Do YYYY")}</span>
            ),
        },
        {
            title: "Updated At",
            key: "updated_at",
            sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
            render: (text, record) => (
                <span>
                    {moment(record.updated_at).isAfter(moment(record.created_at))
                        ? moment(record.updated_at).format("MMMM Do YYYY")
                        : "-"}
                </span>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <div className="actionButtons">
                    <Button
                        className="btnFilled me-2"
                        style={{ height: "auto" }}
                        onClick={() => handleEdit(record)}
                    >
                        Edit
                    </Button>
                    <Button
                        className="btnFilled me-2"
                        style={{ height: "auto" }}
                        onClick={() => handleResendSwot(record)}
                    >
                        Resend Swot
                    </Button>
                    <Switch
                        defaultChecked={record.status == 1 ? true : false}
                        onChange={() =>
                            onToggle(record.status == 1 ? true : false, record)
                        }
                    />
                    {/* <Button
              className="btnDanger"
              style={{ height: "auto" }}
              onClick={() => handleDelete(record)}
            >
              Delete
            </Button> */}
                </div>
            ),
        },
    ];

    const onChange = (page, filters, sorter) => {
        if (Object.keys(sorter).length !== 0) {
            page.sort = dataBaseFields[sorter.order];
            page.sortBy = sorter.field;
        }
        setCurrent(page);
        getSwotSchedules(page);
    };

    const handleEdit = (record) => {
        // Handle edit logic here
        let dep = [];
        record?.departments?.map((department) => (
            dep.push(department.id)
        ));
        let emp = [];
        record?.employees?.map((employee) => (
            emp.push(employee.id)
        ));
        navigate("/schedule-swot", {
            state: { schedule: record, employee: emp, department: dep },
        });
    };

    const handleResendSwot= (record) => {
        // Handle edit logic here
        SwotService.resendSwot(record.id)
        .then((response) => {
            setLoader(false);
            Swal.fire({
                title: "Swot Sent",
                icon: "success",
            })
        })
        .catch((err) => {
            let message = err.response?.data?.error;
            Swal.fire({
                title: "Oops...",
                text: message,
                icon: "error",
            });
            console.log({ err });
        });
     console.log({record})
    };

    const handleOnclick = () => {
        navigate("/schedule-swot");
    };

    const handleDelete = (record) => {
        // Handle delete logic here
        SwotService.deleteSwotSchedules(record.id)
            .then((response) => {
                getSwotSchedules();
                Swal.fire({
                    title: "Success",
                    text: "Swot Schedule Delete",
                    icon: "success",
                });
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };
    let navigate = useNavigate();
    const [swotSchedules, setSwotSchedules] = useState([]);
    const getSwotSchedules = (page, filter) => {
        filter = JSON.stringify(filter);
        SwotService.getSwotSchedules(page, filter)
            .then((response) => {
                setSwotSchedules(response.data);
                setwidgetData(response?.widget_data);
                setCurrent(response.pagination);
                setLoader(false);
            })
            .catch((err) => {
                let message = err.response?.data?.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };



    useEffect(() => {
        getSwotSchedules({}, []);
    }, []);

    const handleFilterSearch = () => {
        if (filter[1].filter_value === '' && filter[0].filter_value !== "") {
            getSwotSchedules({}, [
                { filter_key: "name", filter_value: filter[0].filter_value },
            ])
        } else if (filter[1].filter_value !== '' && filter[0].filter_value === "") {
            getSwotSchedules({}, [
                { filter_key: "send_frequency", filter_value: filter[1].filter_value },
            ])
        } else if (filter[1].filter_value === '' && filter[0].filter_value === "") {
            getSwotSchedules({}, [])
        } else {
            getSwotSchedules({}, [
                { filter_key: "name", filter_value: filter[0].filter_value },
                { filter_key: "send_frequency", filter_value: filter[1].filter_value },
            ]);
        }

    };

    return (
        <>
            {loader ? (
                <div className="spinner">
                    <Spin />
                </div>
            ) : (
                <div className="mainWrapper">
                    <Card className="card contentCard border-0 pb-5">
                        <div className="row">
                            <div className="col-md-12 mb-4">
                                <div className="pageHeader d-flex justify-content-between align-items-centers">
                                    <h3>Schedule SWOT</h3>
                                    <div className="buttonsWrap">
                                        <Search
                                            placeholder="Search..."
                                            onSearch={handleFilterSearch}
                                            onChange={handleSearch}
                                        />
                                        <Select
                                            className="select-frequency me-2"
                                            showSearch
                                            placeholder="Select Frequency"
                                            optionFilterProp="children"
                                            onChange={onChangeFrequency}
                                            filterOption={filterOption}
                                            options={[
                                                {
                                                    value: "onetime",
                                                    label: "onetime",
                                                },
                                                {
                                                    value: "monthly",
                                                    label: "monthly",
                                                },
                                                {
                                                    value: "weekely",
                                                    label: "weekely",
                                                },
                                                {
                                                    value: "",
                                                    label: "all",
                                                },
                                            ]}
                                        />
                                        <button
                                            type="button"
                                            className="btnFilled"
                                            onClick={() => handleOnclick()}
                                        >
                                            <Plus />
                                            Create Swot Schedule
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mb-4">
                                <div className="innerCol colFirst">
                                    <p className="swotLabel">Total SWOT</p>
                                    <h4>{widgetData?.total_swot}</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mb-4">
                                <div className="innerCol colSecond">
                                    <p className="swotLabel">Active SWOT</p>
                                    <h4>{widgetData?.active_swot}</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mb-4">
                                <div className="innerCol colThird">
                                    <p className="swotLabel">Upcoming SWOT</p>
                                    <h4>{widgetData?.upcoming_swot}</h4>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="innerCol colFourth">
                                    <p className="swotLabel">Completed SWOT</p>
                                    <h4>{widgetData?.completed_swot}</h4>
                                </div>
                            </div>
                            <div className="col-md-12 mt-5">
                                <div className="table-responsive">
                                    <Table
                                        dataSource={swotSchedules}
                                        columns={columns}
                                        rowKey="id"
                                        loading={loader}
                                        pagination={{
                                            pageSize: current?.pageSize ? current.pageSize : "10",
                                            total: current?.total ? current.total : 10,
                                            pageSizeOptions: [10, 20, 50, 100],
                                            locale: "",
                                            showSizeChanger: true,
                                        }}
                                        sortDirections={["descend", "ascend", "descend"]}
                                        defaultSortOrder={"descend"}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            )}
        </>
    );
};

export default SwotSchedulesList;
