import React, { useState, useEffect } from "react";
import {
    Form,
    Input,
    Select,
    DatePicker,
    TimePicker,
    Radio,
    Button,
    Card,
} from "antd";
import Swal from "sweetalert2";
import * as SwotService from "../../services/SwotService";
import * as EmployeeService from "../../services/Employee";
import * as Department from "../../services/Department";
import "../../styles/swot.css";
import "../../styles/scheduleSwot.css";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { MdRefresh } from "react-icons/md";

const { Option } = Select;
const { TextArea } = Input;

const ScheduleSwot = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [sendFrequency, setSendFrequency] = useState("onetime");
    const [sentTo, setSentTo] = useState(state?.department.length > 0 ? "department" : "employee");
    const [employees, setEmployees] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [loader, setLoader] = useState(false);
    const schedule = state?.schedule ? state?.schedule : {};

    const button = state?.schedule ? "Update" : "Submit";
    const [form] = Form.useForm();

    const handleSentToChange = (e) => {
        setSentTo(e.target.value);
    };

    useEffect(() => {
        Department.getDepartments()
            .then((response) => {
                setDepartments(response?.data);
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
        EmployeeService.getEmployees()
            .then((response) => {
                setEmployees(response?.data);
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    }, []);

    const handleSendFrequencyChange = (value) => {
        setSendFrequency(value);
    };

    const handleSubmit = (values) => {
        setLoader(true)
        if (sendFrequency !== "onetime") {
            const sendTime = new Date(values.send_time);
            const hours = sendTime.getHours();
            const minutes = sendTime.getMinutes();
            const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
                .toString()
                .padStart(2, "0")}`;
            values.send_time = formattedTime;
        }

        if (sendFrequency === "monthly") {
            const sendTime = new Date(values.send_date);

            // Extracting date components
            const year = sendTime.getUTCFullYear();
            const month = (sendTime.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so add 1
            const day = sendTime.getUTCDate().toString().padStart(2, "0");

            // Creating a formatted date string (YYYY-MM-DD)
            const formattedDate = `${year}-${month}-${day}`;
            values.send_date = formattedDate;
        }
        if (state?.schedule) {
            SwotService.updatescheduleSwot(values, schedule.id)
                .then((response) => {
                    Swal.fire({
                        title: "Swot Updated",
                        icon: "success",
                    }).then(() => {
                        setLoader(false)
                        navigate("/schedule-swotlist");
                    });
                })
                .catch((err) => {
                    setLoader(false)
                    let message = err.response.data.error;
                    Swal.fire({
                        title: "Oops...",
                        text: message,
                        icon: "error",
                    });
                    console.log({ err });
                });
        } else {
            SwotService.scheduleSwot(values)
                .then((response) => {
                    setLoader(false)
                    Swal.fire({
                        title: "Created...",
                        text: "Swot Schedule Created Successfully!",
                        icon: "success",
                    }).then(() => {
                        navigate("/schedule-swotlist");
                    });
                })
                .catch((err) => {
                    setLoader(false)
                    let message = err.response.data.error;
                    Swal.fire({
                        title: "Oops...",
                        text: message,
                        icon: "error",
                    });
                    console.log({ err });
                });
        }

        // Here you can add your logic to submit the form data
    };

    const handleEmployeeChange = (value) => {
        if (value.includes('all')) {
            let emp_ids = [];
            employees?.map((employee) => { if (employee.status === "active") { emp_ids.push(employee.id) } });
            form.setFieldsValue({ employee_ids: emp_ids });
        }
        // setSendFrequency(value);
    };

    const handleDepartmentChange = (value) => {
        if (value.includes('all')) {
            let depart_ids = [];
            departments?.map((department) => depart_ids.push(department.id));
            form.setFieldsValue({ department_ids: depart_ids });
        }
        // setSendFrequency(value);
    };


    return (
        <div className="mainWrapper">
            <Card className="card contentCard border-0 pb-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className="pageHeader d-flex align-items-center analysisPageHeader">
                            <button
                                type="button"
                                className="backIcon btnOutline mb-4 me-4"
                                onClick={() => navigate(-1)}
                            >
                                <IoMdArrowRoundBack />
                                Back
                            </button>
                            <h3>{state?.schedule ? "Edit " : "Create "} Schedule Swot</h3>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <Form
                            form={form}
                            onFinish={handleSubmit}
                            className="swotForm"
                            layout="vertical"
                        >
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        initialValue={schedule.name ? schedule.name : ""}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input SWOT Name!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled={loader}
                                            className="form-control"
                                            placeholder="Enter Schedule Swot name"
                                        />
                                    </Form.Item>
                                    {sentTo === "employee" && (
                                        <Form.Item
                                            label="Select Employees"
                                            name="employee_ids"
                                            initialValue={state?.employee}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select an Employees!",
                                                },
                                            ]}
                                        >
                                            <Select
                                                disabled={loader}
                                                mode="multiple"
                                                style={{ width: "100%" }}
                                                placeholder="Select Employee Want to send Swot"
                                                showSearch={true}
                                                onChange={handleEmployeeChange}
                                                optionFilterProp="label"
                                            >
                                                <Option value="all">Select All</Option>
                                                {employees?.map((employee) => {
                                                    if (employee.status === "active") {
                                                        return (
                                                            <Option
                                                                value={employee.id}
                                                                label={employee.full_name}
                                                            >
                                                                {employee.full_name}
                                                            </Option>
                                                        );
                                                    }
                                                })}
                                            </Select>
                                        </Form.Item>
                                    )}
                                    {sentTo === "department" && (
                                        <Form.Item
                                            label="Select Departments"
                                            name="department_ids"
                                            initialValue={state?.department}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select an Departments!",
                                                },
                                            ]}
                                        >
                                            <Select
                                                disabled={loader}
                                                mode="multiple"
                                                style={{ width: "100%" }}
                                                placeholder="Select Department Want to send Swot"
                                                showSearch={true}
                                                onChange={handleDepartmentChange}
                                                optionFilterProp="label"
                                            >
                                                <Option value="all">Select All</Option>
                                                {departments?.map((department) => {
                                                    return (
                                                        <Option
                                                            value={department.id}
                                                            label={department.name}
                                                        >
                                                            {department.name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    )}
                                    {sendFrequency === "monthly" && (
                                        <Form.Item
                                            label="Send Date"
                                            name="send_date"
                                            initialValue={
                                                schedule.send_date ? schedule.send_date : ""
                                            }
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select Send Date!",
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                disabled={loader}
                                                style={{
                                                    width: "100%",
                                                }}
                                            />
                                        </Form.Item>
                                    )}
                                    {sendFrequency === "weekly" && (
                                        <Form.Item
                                            label="Send Day of Week"
                                            name="send_day_of_week"
                                            initialValue={
                                                schedule.send_day_of_week
                                                    ? schedule.send_day_of_week
                                                    : ""
                                            }
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select Send Day of Week!",
                                                },
                                            ]}
                                        >
                                            <Select disabled={loader} placeholder="Select Day of Week When You want to Send SWOT">
                                                <Option value="mon">Monday</Option>
                                                <Option value="tue">Tuesday</Option>
                                                <Option value="wed">Wednesday</Option>
                                                <Option value="thu">Thursday</Option>
                                                <Option value="fri">Friday</Option>
                                            </Select>
                                        </Form.Item>
                                    )}
                                    <Form.Item
                                        label="Expiry Time Unit"
                                        name="expiry_time_unit"
                                        initialValue={
                                            schedule.expiry_time_unit
                                                ? schedule.expiry_time_unit
                                                : "minutes"
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select Expiry Time Unit",
                                            },
                                        ]}
                                    >
                                        <Select disabled={loader}>
                                            <Option value="minutes">Minutes</Option>
                                            <Option value="hours">Hours</Option>
                                            <Option value="days">Days</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-md-6">
                                    <div className="d-flex mb-35">
                                        <Form.Item
                                            label="Send To"
                                            name="Send"
                                            initialValue={
                                                state?.department.length > 0
                                                    ? "department"
                                                    : "employee"
                                            }
                                        >
                                            <Radio.Group
                                                onChange={handleSentToChange}
                                                disabled={
                                                    state?.department.length > 0 ||
                                                        state?.employee.length > 0
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <Radio value="employee">Employee</Radio>
                                                <Radio value="department">Department</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item
                                            label="Send Via"
                                            name="send_via"
                                            initialValue={
                                                schedule.send_via ? schedule.send_via : "email"
                                            }
                                        >
                                            <Radio.Group disabled={loader}>
                                                <Radio value="email">Email</Radio>
                                                <Radio value="sms">SMS</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                    <Form.Item
                                        label="Select Frequency"
                                        name="send_frequency"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select Frequency!",
                                            },
                                        ]}
                                        initialValue={
                                            schedule.send_frequency
                                                ? schedule.send_frequency
                                                : "onetime"
                                        }
                                    >
                                        <Select
                                            placeholder="Select Send Frequency"
                                            onChange={handleSendFrequencyChange}
                                            value={sendFrequency}
                                            disabled={
                                                state?.department.length > 0 ||
                                                    state?.employee.length > 0
                                                    ? true
                                                    : false
                                            }
                                            className="mb-3"
                                        >
                                            <Option value="onetime">One-time</Option>
                                            <Option value="monthly">Monthly</Option>
                                            <Option value="weekly">Weekly</Option>
                                        </Select>
                                    </Form.Item>
                                    {sendFrequency !== "onetime" && (
                                        <Form.Item
                                            label="Send Time"
                                            name="send_time"
                                            initialValue={
                                                schedule.send_time ? schedule.send_time : ""
                                            }
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select Send Time!",
                                                },
                                            ]}
                                        >
                                            <TimePicker
                                                disabled={loader}
                                                style={{
                                                    width: "100%",
                                                }}
                                            />
                                        </Form.Item>
                                    )}
                                    <Form.Item
                                        label="Expiry Time"
                                        name="expiry_time"
                                        initialValue={
                                            schedule.expiry_time ? schedule.expiry_time : ""
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select Expiry Time!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled={loader}
                                            className="form-control"
                                            type="number"
                                            placeholder="Enter Expiry Time According to Expiry Time Unit"
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-md-12">
                                    <Form.Item
                                        label="Manager Intro Text"
                                        name="manager_intro_text"
                                        initialValue={
                                            schedule.manager_intro_text
                                                ? schedule.manager_intro_text
                                                : ""
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Add Manager Intro Text!",
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            disabled={loader}
                                            className="form-textarea"
                                            rows={4}
                                            showCount
                                            maxLength={200}
                                            placeholder="Enter Manager Intro Text"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Manager Terms Privacy"
                                        name="manager_terms_privacy"
                                        initialValue={
                                            schedule.manager_terms_privacy
                                                ? schedule.manager_terms_privacy
                                                : ""
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Add Manager Terms Privacy!",
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            disabled={loader}
                                            className="form-textarea"
                                            rows={4}
                                            showCount
                                            maxLength={200}
                                            placeholder="Enter Manager Terms Privacy"
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-md-12 text-center">
                                    <Button type="primary" htmlType="submit" icon={<MdRefresh />}
                                        loading={loader} disabled={loader}>
                                        {button}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default ScheduleSwot;
