import React, { useState, useEffect } from "react";
import { Dropdown, Drawer, Layout, Button, Menu } from "antd";
import { UserOutlined,TransactionOutlined } from "@ant-design/icons";
import { TbLogout } from "react-icons/tb";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
    UserAvatar,
    Department,
    Employees,
    Schedule,
    Swot,
    Dashboard,
} from '../constant/icons';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import Logo from '../assets/images/logo.jpg';
import { RxUpdate } from "react-icons/rx";
import { CgWebsite } from "react-icons/cg";
import { GrTemplate } from "react-icons/gr";

const { Header } = Layout;

const HeaderComponent = () => {
    let navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState();

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const handleOnClick = () => {
        navigate("/update-company");
    };
    const handleChnagePassword = () => {
      navigate("/change-password");
    };

    const handleTransactions = () => {
        window.location.href = "https://swot.aleait.dev/my-account/orders"
      };

    const logout = () => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("login_modal");
        window.location.href = "https://swot.aleait.dev/login/?action=logout";
    };

    useEffect(() => {
        const path = location.pathname;
        let key = path;

        if (path === "/create-department") {
            key = "/department";
        } else if (path === "/create-employees") {
            key = "/employees";
        } else if (path === "/schedule-swot") {
            key = "/schedule-swotlist";
        } else if (path === "/swot-response" || path === "/swot_analysis") {
            key = "/swot-list";
        }

        setSelectedKey(key);
    }, [location.pathname]);

    const itemsMenu = [
        {
            key: "/dashboard",
            icon: <Dashboard className="menu-icon" />,
            path: "/dashboard",
            label: <Link to={"/dashboard"}>{"Dashboard"}</Link>,
            className: "custom-menu-item",
        },
        {
            key: "/department",
            icon: <Department className="menu-icon" />,
            path: "/department",
            label: <Link to={"/department"}>{"Department"}</Link>,
            className: "custom-menu-item",
        },
        {
            key: "/employees",
            icon: <Employees className="menu-icon" />,
            path: "/employees",
            label: <Link to={"/employees"}>{"Employees"}</Link>,
            className: "custom-menu-item",
        },
        {
            key: "/schedule-swotlist",
            icon: <Schedule className="menu-icon" />,
            path: "/schedule-swotlist",
            label: <Link to={"/schedule-swotlist"}>{"Schedule Swot List"}</Link>,
            className: "custom-menu-item",
        },
        {
            key: "/swot-list",
            icon: <Swot className="menu-icon" />,
            path: "/swot-list",
            label: <Link to={"/swot-list"}>{"Swot Analysis"}</Link>,
            className: "custom-menu-item",
        },
        {
                    key: "/templates",
                    icon: <GrTemplate className="menu-icon" size={20} color="#fff"/>,
                    path: "/templates",
                    label: <Link to={"/templates"}>{"Templates"}</Link>,
                    className: "custom-menu-item",
                },
                {
                    key: "go-to-website",
                    icon: <CgWebsite className="menu-icon" size={20} color="#fff" />,
                    label: (
                        <span
                        className="go-to-website-text"
                            onClick={() => window.location.href = "https://swot.aleait.dev"}
                            style={{ cursor: "pointer" }}
                        >
                            {"Go to Website"}
                        </span>
                    ),
                    className: "custom-menu-item",
                },
    ];

    const items = [
      {
        key: 1,
        label: (
          <>
            <div onClick={handleOnClick}>
              <UserOutlined /> Profile
            </div>
          </>
        ),
      },
      {
        key: 2,
        label: (
          <>
            <div onClick={handleChnagePassword}>
              <RxUpdate /> Change Password
            </div>
          </>
        ),
      },
      {
        key: 3,
        label: (
          <>
            <div onClick={handleTransactions}>
            <TransactionOutlined /> My Transactions
            </div>
          </>
        ),
      },
      {
        key: 4,
        label: (
          <div onClick={logout}>
            <TbLogout /> Logout{" "}
          </div>
        ),
      },
    ];

    return (
        <>
            <Header
                style={{
                }}
                className="headerComponent"
            >
                <Dropdown
                    menu={{
                        items,
                    }}
                    className="dropIcon"
                    trigger={'click'}
                >
                    <UserAvatar />
                </Dropdown>
                <Button
                    type="secondary"
                    onClick={showDrawer}
                    className="drawerButton"
                >
                    {open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Button>
            </Header>
            <Drawer className="sidebar" onClose={onClose} open={open} placement='left'>
                <div className="demo-logo-vertical">
                    <Link to={"/dashboard"}>
                        <img src={Logo} className="img-fluid" alt="logo" />
                    </Link>
                </div>
                <Menu mode="inline" theme={'#09074D'} items={itemsMenu} selectedKeys={[selectedKey]} onClick={onClose}/>
            </Drawer>
        </>
    );
};

export default HeaderComponent;
