import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "summernote/dist/summernote-bs4.css";
import "summernote/dist/summernote-bs4.js";

const SummernoteEditor = ({ value, onChange }) => {
  const editorRef = useRef(null);
  const initializedRef = useRef(false);

  useEffect(() => {
    if (!initializedRef.current) {
      const $editor = $(editorRef.current);
      
      $editor.summernote({
        height: 300,
        placeholder: "Write your content here...",
        callbacks: {
          onChange: function (contents) {
            if (onChange) {
              onChange(contents);
            }
          },
          onInit: function() {
            if (value) {
              $editor.summernote('code', value);
            }
          }
        },
        toolbar: [
          ['style', ['style']],
          ['font', ['bold', 'italic', 'underline', 'clear']],
          ['fontname', ['fontname']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['table', ['table']],
          ['insert', ['link', 'picture']],
          ['view', ['fullscreen', 'codeview']]
        ]
      });

      initializedRef.current = true;

      // Cleanup on unmount
      return () => {
        $editor.summernote('destroy');
        initializedRef.current = false;
      };
    }
  }, []);

  // Handle value updates from parent
  useEffect(() => {
    if (initializedRef.current) {
      const $editor = $(editorRef.current);
      const currentContent = $editor.summernote('code');
      
      if (value !== currentContent) {
        $editor.summernote('code', value);
      }
    }
  }, [value]);

  return (
    <div>
      <div ref={editorRef}></div>
    </div>
  );
};

export default SummernoteEditor;