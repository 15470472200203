import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import SwotResponse from "./pages/SwotResponse";
import EmployeeList from "./pages/Employee/EmployeeList";
import CreateEmployee from "./pages/Employee/CreateEmployee";
import ScheduleSwot from "./pages/Swot/ScheduleSwot";
import SwotSchedulesList from "./pages/Swot/SwotSchedulesList";
import CreateDepartment from "./pages/Department/CreateDepartment";
import DepartmentList from "./pages/Department/DepartmentList";
import SwotResponseList from "./pages/Swot/SwotResponseList";
import UpdateCompany from "./pages/Company/UpdateCompany";
import VerifyEmail from "./pages/VerifyEmail";
import EmployeeSwotList from "./pages/Employee/EmployeeSwotList";
import EmployeeSwotResponseList from "./pages/Employee/EmployeeSwotResponseList";
import Swots from "./pages/Swot/Swots";
import "bootstrap/dist/css/bootstrap.min.css";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import ChangePassword from "./pages/ChangePassword";
import Dashboard from "./pages/Swot/dashboard";
import SwotAnalysis from "./pages/Swot/SwotAnalysis";
import { Layout } from 'antd';
import SidebarComponent from './Layout/Sidebar';
import HeaderComponent from './Layout/Header';
import Templates from "./pages/Templates/Templates";
import EditTemplate from "./pages/Templates/EditTemplate";
import LoginSignUp from "./pages/Templates/LoginSignUp";
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'summernote/dist/summernote-lite.css';
import 'summernote/dist/summernote-lite.min.js';
import $ from 'jquery';


const { Content, Footer } = Layout;

function App() {
    const location = useLocation();
    const queryParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = queryParams.get('token');

    if(tokenFromUrl){
        localStorage.setItem("access_token",tokenFromUrl)
        localStorage.setItem("login_modal", true)
    }
    const noLayoutPaths = ["/", "/signUp", "/swot", "/verify_email", "/forgot-password", "/reset-password"];
    const isLoggedIn = localStorage.getItem("access_token");

    const shouldRenderLayout = !noLayoutPaths.includes(location.pathname);

    
    return (
        <>
            {shouldRenderLayout ? (
                <Layout>
                    <SidebarComponent />
                    <Layout>
                        <HeaderComponent />
                        <Content
                            className='contentComponent'
                            style={{
                                marginLeft: 300,
                            }}
                        >
                            <Routes>
                                {isLoggedIn ? (
                                    <>
                                        <Route path="/update-company" element={<UpdateCompany />} />
                                        <Route path="/department" element={<DepartmentList />} />
                                        <Route path="/create-department" element={<CreateDepartment />} />
                                        <Route path="/employees" element={<EmployeeList />} />
                                        <Route path="/create-employees" element={<CreateEmployee />} />
                                        <Route path="/swot" element={<SwotResponse />} />
                                        <Route path="/verify_email" element={<VerifyEmail />} />
                                        <Route path="/swot-list" element={<Swots />} />
                                        <Route path="/employee-swot-list" element={<EmployeeSwotList />} />
                                        <Route path="/employee-swot-response" element={<EmployeeSwotResponseList />} />
                                        <Route path="/schedule-swot" element={<ScheduleSwot />} />
                                        <Route path="/schedule-swotlist" element={<SwotSchedulesList />} />
                                        <Route path="/swot-response" element={<SwotResponseList />} />
                                        <Route path="/change-password" element={<ChangePassword />} />
                                        <Route path="/dashboard" element={<Dashboard />} />
                                        <Route path="/swot_analysis" element={<SwotAnalysis />} />
                                        <Route path="/templates" element={<Templates />} />
                                        <Route path="/edit-template" element={<EditTemplate />} />
                                        <Route path="*" element={<Navigate to="/dashboard" />} />
                                    </>
                                ) : (
                                    <>
                                        <Route path="/login" element={<Login />} />
                                        <Route path="/signUp" element={<SignUp />} />
                                        <Route path="/swot" element={<SwotResponse />} />
                                        <Route path="/verify_email" element={<VerifyEmail />} />
                                        <Route path="/forgot-password" element={<ForgotPassword />} />
                                        <Route path="/reset-password" element={<ResetPassword />} />
                                        <Route path="*" element={<Navigate to="/" />} />
                                    </>
                                )}
                            </Routes>
                            <Footer className='footerCol'>
                                ©{new Date().getFullYear()}, made with ❤️ by AleaIT Solutions Pvt Ltd for a better web.
                            </Footer>
                        </Content>
                    </Layout>
                </Layout>
            ) : (
                <Routes>
                    {isLoggedIn ? (
                        <>
                            <Route path="/update-company" element={<UpdateCompany />} />
                            <Route path="/department" element={<DepartmentList />} />
                            <Route path="/create-department" element={<CreateDepartment />} />
                            <Route path="/employees" element={<EmployeeList />} />
                            <Route path="/create-employees" element={<CreateEmployee />} />
                            <Route path="/swot" element={<SwotResponse />} />
                            <Route path="/verify_email" element={<VerifyEmail />} />
                            <Route path="/swot-list" element={<Swots />} />
                            <Route path="/employee-swot-list" element={<EmployeeSwotList />} />
                            <Route path="/employee-swot-response" element={<EmployeeSwotResponseList />} />
                            <Route path="/schedule-swot" element={<ScheduleSwot />} />
                            <Route path="/schedule-swotlist" element={<SwotSchedulesList />} />
                            <Route path="/swot-response" element={<SwotResponseList />} />
                            <Route path="/change-password" element={<ChangePassword />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/swot_analysis" element={<SwotAnalysis />} />
                            <Route path="/templates" element={<Templates />} />
                            <Route path="/edit-template" element={<EditTemplate />} />
                            <Route path="*" element={<Navigate to="/dashboard" />} />
                        </>
                    ) : (
                        <>
                            <Route path="/" element={<LoginSignUp />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/signUp" element={<SignUp />} />
                            <Route path="/swot" element={<SwotResponse />} />
                            <Route path="/verify_email" element={<VerifyEmail />} />
                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                            <Route path="*" element={<Navigate to="/" />} />
                        </>
                    )}
                </Routes>
            )}
        </>
    );
}

export default App;
