import React, { useState } from "react";
import { Button, Form, Input, Checkbox } from "antd";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import * as AuthService from "../services/AuthService";
import Logo from '../assets/images/logo.jpg';
import { MdRefresh } from "react-icons/md";

const Login = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false)

    const onFinish = (values) => {
        setLoader(true)
        AuthService.loginCompany(values)
            .then(async (response) => {
                localStorage.setItem("access_token", response.token);
                localStorage.setItem("login_modal", true)
                setLoader(false)
                navigate("/dashboard");
            })
            .catch((err) => {
                setLoader(false)
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    return (
        <div className="authWrapper">
            <div className="innerWrapper">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className="logoSection">
                                <img src={Logo} className="img-fluid" alt="logo" />
                            </div>
                            <div className="card authCard">
                                <div className="card-body">
                                    <div className="formWrapper">
                                        <div className="headingWrapper">
                                            <h2 className="heading">Login</h2>
                                            <p className="subText">Harness the power of SWOT analysis to strategically assess your strengths, weaknesses, opportunities, and threats.</p>
                                        </div>
                                        <Form
                                            className="formMain authForm"
                                            name="basic"
                                            title="Login"
                                            initialValues={{
                                                remember: true,
                                            }}
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            autoComplete="off"
                                            layout="vertical"
                                        >
                                            <Form.Item
                                                className="formContent"
                                                label="Email"
                                                name="admin_email"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter a valid email.",
                                                        type: "email",
                                                    },
                                                    {
                                                        validator(_, value) {
                                                            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z]*[a-zA-Z0-9]+[a-zA-Z]+\.[a-zA-Z]{2,4}$/;
                                                            if (value && !regex.test(value)) {
                                                                return Promise.reject(
                                                                    "Invalid email address. The website name must contain both letters and numbers, and the domain should not exceed 4 characters."
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    maxLength={30}
                                                    placeholder="Enter email address"
                                                    disabled={loader}
                                                    className="inpt formControl"
                                                    onInput={(e) => {
                                                        let value = e.target.value;
                                                        value = value.replace(/['"\s]/g, '');

                                                        const atIndex = value.indexOf('@');
                                                        if (atIndex !== -1) {
                                                            const localPart = value.slice(0, atIndex + 1);
                                                            let domainPart = value.slice(atIndex + 1);
                                                            domainPart = domainPart.replace(/[0-9]/g, '');
                                                            const domainRegex = /\.(com|org|net|co|edu|gov|io|info|biz|[a-zA-Z]{2,4})$/;
                                                            const domainMatch = domainPart.match(domainRegex);
                                                            if (domainMatch) {
                                                                domainPart = domainPart.slice(0, domainMatch.index + domainMatch[0].length);
                                                            }
                                                            const domainSplit = domainPart.split('.');
                                                            if (domainSplit.length > 1 && domainSplit[1].length > 4) {
                                                                domainSplit[1] = domainSplit[1].slice(0, 4);
                                                                domainPart = domainSplit.join('.');
                                                            }

                                                            value = localPart + domainPart;
                                                        }

                                                        e.target.value = value;
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                className="formContent mb-3"
                                                label="Password"
                                                name="password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your password!",
                                                    },
                                                ]}
                                            >
                                                <Input.Password disabled={loader} className="inpt formControl" />
                                            </Form.Item>
                                            <div className="forgotRow mb-4">
                                                <Checkbox onChange={onChange} className="formCheck">Remember me</Checkbox>
                                                <Link to={'/forgot-password'} className="forgotLink">Forgot password?</Link>
                                            </div>
                                            <div className="text-center">
                                                <Button className="btnPrimary w-50" type="primary" htmlType="submit" icon={<MdRefresh />}
                                                    loading={loader} disabled={loader}>
                                                    Login
                                                </Button>
                                            </div>
                                            <Form.Item className="bottomText">
                                                Don't have an account? <Link to="/signUp" className="bottomTextLink">Sign Up</Link>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Login;
