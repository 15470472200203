import React, { useEffect, useState,useRef,useMemo } from "react";
import { Table, Card } from "antd";
import * as SwotService from "../../services/SwotService";
import Swal from "sweetalert2";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import PageSizeOptionsHelper from "../../Helpers/PageSizeOptionsHelper";
import { Download } from "../../constant/icons";
import { IoIosRefresh } from "react-icons/io";
import { saveAs } from 'file-saver';

const SwotResponseList = () => {
    const [loader, setLoader] = useState(true);
    const [buttonLoader, setButtonLoader] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerpage] = useState(5);
    const [swotResponse, setResponse] = useState([]);
    const [total, setTotal] = useState(0);
    const { state } = useLocation();
 
    const prevSwotRef = useRef(null);
    const prevSwotIdRef = useRef(null);

    const { swot, swotId } = useMemo(() => {
    if (state === null) {
        return {
        swot: prevSwotRef.current,
        swotId: prevSwotIdRef.current,
        };
    }

    prevSwotRef.current = state?.data;
    prevSwotIdRef.current = state?.data?.swot_id;

    return {
        swot: state?.data,
        swotId: state?.data?.swot_id,
    };
    }, [state]);
    const navigate = useNavigate();

    const getSwots = (swotId, query) => {
        SwotService.getSwotResponseBySwotId(swotId, query)
            .then((response) => {
                setTotal(response?.pagination?.total)
                setResponse(response?.responses);
                setLoader(false);
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };

    const pageSizeOptions = PageSizeOptionsHelper(total);

    const columns = [
        {
            title: "S.No.",
            dataIndex: "serial",
            key: "serial",
            render: (text, record, index) => {
                return (currentPage - 1) * perPage + index + 1;
            },
        },
        {
            title: "Strengths",
            dataIndex: "strengths",
            key: "strengths",
            render: (text, record) => <div className="strenthsTableRow">{text}</div>,
        },
        {
            title: "Weaknesses",
            dataIndex: "weaknesses",
            key: "weaknesses",
        },
        {
            title: "Opportunities",
            dataIndex: "opportunities",
            key: "opportunities",
        },
        {
            title: "Threats",
            dataIndex: "threats",
            key: "threats",
        },
    ];

    useEffect(() => {
        if (swotId) {
            const query = `&current=${currentPage}&pageSize=${perPage}`;
            getSwots(swotId, query);
        }
    }, [swotId, currentPage, perPage]);

    const pageSizeChange = (page, perPage) => {
        setCurrentPage(page);
        setPerpage(perPage);
    }

    const handleResponseDownload = async () => {
        setButtonLoader(true);
        try {
            const query = `&current=1&pageSize=150`;
            const response = await SwotService.getSwotResponseBySwotId(swotId, query)
            const swotResponse = response?.responses || [];

            const csvData = swotResponse.map((swot, index) => {
                const cleanText = (text) => {
                    return text ? text.replace(/\n+/g, ' ').trim() : '';
                };
            

                return {
                    "S.NO": index + 1,
                    "Strength": cleanText(swot?.strengths),
                    "Weaknesses": cleanText(swot?.weaknesses),
                    "Opportunities": cleanText(swot?.opportunities),
                    "Threats": cleanText(swot?.threats),
                    "Responded On": swot.created_at,


                };
            });

            const csvRows = [
                ['S.NO', 'Strength', 'Weaknesses', 'Opportunities', 'Threats', 'Responded On'],
                ...csvData.map(item => [
                    item['S.NO'],
                    item["Strength"],
                    item["Weaknesses"],
                    item["Opportunities"],
                    item["Threats"],
                    item["Responded On"],
                ])
            ];

            const today = new Date();
            const day = String(today.getDate()).padStart(2, '0');
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const year = today.getFullYear();

            const formattedDate = `${day}/${month}/${year}`;

            const csvString = csvRows.map(row => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, `${swot?.swot_name} ${formattedDate} swot_response.csv`);
        } catch (error) {
            console.error("Error exporting data: ", error);
        }
        setButtonLoader(false);
    };

    return (
        <div className="mainWrapper">
            <Card className="card contentCard border-0 mb-4">
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <div className="pageHeader d-flex align-items-center analysisPageHeader response-container">
                            <button
                                type="button"
                                className="backIcon btnOutline mb-4 me-4"
                                onClick={() => navigate(-1)}
                            >
                                <IoMdArrowRoundBack />
                                Back
                            </button>
                            <h3>SWOT Response</h3>
                            <button onLoad={buttonLoader} onClick={() => handleResponseDownload()} icon={<IoIosRefresh />}  className="btnOutlineFilled me-2 response-download-button">
                                <Link
                                    className="employee-csv-download"

                                >
                                    <Download />
                                    Download Response in CSV
                                </Link>
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4">
                        <div className="innerCol colFirst">
                            <p className="swotLabel">SWOT Name</p>
                            <h4>{swot?.swot_name}</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4">
                        <div className="innerCol colSecond">
                            <p className="swotLabel">Sent To</p>
                            <h4>{swot?.recipient_count}</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4">
                        <div className="innerCol colThird">
                            <p className="swotLabel">Filled By</p>
                            <h4>{swot?.response_count}</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="innerCol colFourth">
                            <p className="swotLabel">SWOT Percentage</p>
                            <h4>
                                {((swot?.response_count / swot?.recipient_count) * 100).toFixed(
                                    2
                                )}{" "}
                                %
                            </h4>
                        </div>
                    </div>
                    <div className="col-md-12 mt-5">
                        <Table
                            className="swotTable"
                            dataSource={swotResponse}
                            columns={columns}
                            pagination={{
                                total: total,
                                current: currentPage,
                                pageSize: perPage,
                                showSizeChanger: true,
                                onChange: pageSizeChange,
                                pageSizeOptions: pageSizeOptions
                            }}
                            rowKey="id"
                            loading={loader}
                            bordered
                        />
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default SwotResponseList;
