import axios from "axios";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(function (config) {
  const token = `${localStorage.getItem("access_token")}`;
  config.headers.Authorization = token;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("login_modal");
        window.location.href = "https://swot.aleait.dev/login/?action=logout";
    }
    return Promise.reject(error);
  }
);

export default axios;
