import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import * as Department from "../services/Department";
import * as Swot from "../services/SwotService";
import Swal from "sweetalert2";
import { Select } from "antd";

const SwotLineChart = () => {
    const [selecteddepartment, setSelectedDepartment] = useState("");
    const [swotData, setSwotData] = useState({});

    const { Option } = Select;
    const [departments, setDepartments] = useState([]);

    const getGraphData = (id) => {
        Swot.getSwotGraph(id)
            .then((response) => {
                setSwotData(response);
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
    };

    useEffect(() => {
        Department.getDepartments()
            .then((response) => {
                setDepartments(response?.data);
            })
            .catch((err) => {
                let message = err.response.data.error;
                Swal.fire({
                    title: "Oops...",
                    text: message,
                    icon: "error",
                });
                console.log({ err });
            });
        getGraphData();
    }, []);


    const handleDepartmentChange = (value) => {
        getGraphData(value);
        setSelectedDepartment(value);
    };

    const options = {
        chart: {
            type: "line",
        },
        title: {
            text: "SWOT Analysis",
        },
        xAxis: {
            categories: swotData?.swot_names,
            title: {
                text: "SWOTS",
            },
        },
        credits: {
            enabled: false
        },
        yAxis: {
            title: {
                text: "Percentage",
            },
            min: 0,
            max: 100,
        },
        series: [
            {
                name: "Strength",
                data: swotData.strengths,
            },
            {
                name: "Weakness",
                data: swotData?.weaknesses,
            },
            {
                name: "Opportunities",
                data: swotData?.opportunities,
            },
            {
                name: "Threats",
                data: swotData?.threats, // example data
            },
        ],
    };

    return (
        <div>
            <Select
                className="me-2"
                style={{ width: "40%" }}
                placeholder="Select Department"
                onChange={handleDepartmentChange}
            >
                {departments?.map((department) => {
                    return (
                        <Option key={department.id} value={department.id}>
                            {department.name}
                        </Option>
                    );
                })}
            </Select>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default SwotLineChart;
