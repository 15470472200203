import React from 'react';
import Logo from '../../assets/images/logo.jpg';

export default function LoginSignUp() {
  return (
    <div className="authWrapper">
    <div className="innerWrapper">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-5">
                    <div className="logoSection">
                        <img src={Logo} className="img-fluid" alt="logo" />
                    </div>
                    <div className="card authCard">
                        <div className="card-body">
                            <div className="formWrapper">
                                <div className="headingWrapper">
                                    <h2 className="heading">Login / SignUp</h2>
                                    <p className="subText">Harness the power of SWOT analysis to strategically assess your strengths, weaknesses, opportunities, and threats.</p>
                                </div>
                                <div className='login-signup-wrapper d-flex justify-content-center align-items-center flex-column'>
                                <a href='https://swot.aleait.dev/my-account?from=app' className='login-button btnPrimary mb-4 w-75 text-center'>Login/SignUp</a>
                                </div>
                                 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}
