import React, { useState } from 'react';
import { Select, Form, Button, Input } from "antd";
import * as AuthService from "../../../services/AuthService";
import { MdRefresh } from "react-icons/md";

export default function CompanyKeys({ onData, goBack }) {
	const [form] = Form.useForm();
	const { Option } = Select;
	const [selectedPlatform, setSelectedPlatform] = useState()
	const [loader,setLoader] = useState(false)
	const onFinish = (values) => {
		setLoader(true)

		 let formData = {
			"key_name":values?.platform,
			"key_value":values?.platform_key
		 }
		
		 AuthService.UploadKeys(formData).then((then)=>{
			setLoader(false)
             onData()
			 localStorage.setItem("login_modal",false)
		 }).catch((error)=>{
			setLoader(false)
            console.error({error})
		 })
		// onData(values)
	}

	const onFinishFailed = (errorInfo) => {
		console.error("Failed:", errorInfo);
	};
	return (
		<div>
			<Form
				form={form}
				className="formMain authForm"
				name="basic"
				title="Register"
				initialValues={{
					remember: true,
				}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
				layout="vertical"
			>
				<div className="row">
					<div className="col-md-6">
						<Form.Item
							label="Platform"
							name="platform"
							rules={[
								{
									required: true,
									message: "Please select platform!",
								},
							]}
						>
							<Select
								placeholder="Select Platform"
								className="formSelect"
								showSearch={true}
								onChange={(e) => setSelectedPlatform(e)}
							>
								<Option

									value="open_ai"
								>
									OpenAI
								</Option>
							</Select>
						</Form.Item>
					</div>
					{selectedPlatform ? (
						<div className="col-md-6">
							<Form.Item
								label="Platform Key"
								name="platform_key"
								rules={[
									{
										required: true,
										message: "Please enter platform key!",
									},
								]}
							>
								<Input placeholder='Enter Platform Key' />
							</Form.Item>
						</div>
					) : (<></>)}



				</div>

				<div className="row">
					<div className="col-md-6 text-center">

						<div className="formContent">
							<Button
								className="btnPrimary w-50"
								type="primary"
								onClick={goBack}
							>
								Back
							</Button>
						</div>
					</div>
					<div className="col-md-6 text-center">

						<div className="formContent">
							<Button
								className="btnPrimary w-50"
								type="primary"
								htmlType="submit"
								icon={<MdRefresh/>}
								loading={loader}
								disabled={loader}
							>
								Submit
							</Button>
						</div>
					</div>
				</div>

			</Form>
		</div>
	)
}
