import React, { useEffect, useState } from "react";
import * as SwotService from "../../services/SwotService";
import { Card, Modal } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
    SwotSurveyChart,
    ResponseSurveyChart,
} from "../../Helpers/dashboardsCharts";
import SwotLineChart from "../../Helpers/SwotLineChart";
import {
    Department,
    Schedule,
    Swot,
} from '../../constant/icons';
import { Spin } from "antd";
import ComapnyDetails from "../Company/CompanyDetails/CompanyDetails";
import * as AuthService from "../../services/AuthService"

export default function Dashboard() {
    const [dashboardData, setDashboardData] = useState();
    const [loader, setLoader] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [stepNumber, setStepNumber] = useState(0)

    const handleOpenModal = (details, keyData) => {
        if (details?.staff_size && details?.industry && details?.logo && details?.company_address && details?.country && details?.state && details?.zip && details?.timezone && keyData) {
            localStorage.setItem("login_modal", false)
        }

        const modal_status = localStorage.getItem("login_modal")
        if (modal_status === 'false') {
            setIsModalOpen(false)
        }else{
            setIsModalOpen(true)  
        }
    }


    const getCompanyDetails = async () => {
        try {
            const response = await AuthService.getCompany();
            localStorage.setItem("is_ai_service", response?.is_ai_service);
            return response;
        } catch (err) {
            console.error({ err });
            return null;
        }
    };

    const getKeys = async () => {
        try {
            const response = await AuthService.getKeys();
            return response?.data?.api_keys;
        } catch (err) {
            console.error({ err });
            return null;
        }
    };



    useEffect(() => {
        const fetchData = async () => {
            try {
                const details = await getCompanyDetails();
                const keyData = await getKeys();
                handleOpenModal(details, keyData);
                if (
                    details?.staff_size &&
                    details?.industry &&
                    details?.logo
                ) {
                    if (
                        details?.company_address &&
                        details?.country &&
                        details?.state &&
                        details?.zip &&
                        details?.timezone
                    ) {
                        if (keyData?.length > 0) {
                            handleCloseModal()
                            localStorage.setItem("login_modal", false);
                        } else {
                            setStepNumber(2);
                        }
                    } else {
                        setStepNumber(1);
                    }
                } else if (
                    !details?.staff_size ||
                    !details?.industry ||
                    !details?.logo
                ) {
                    setStepNumber(0);
                }

                const response = await SwotService.getDashboardData();
                setDashboardData(response);
                setLoader(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoader(false); 
            }
        };

        fetchData();
    }, []);


    const items = [
        {
            key: 1,
            label: "Total Swot Schedule",
            icon: <Schedule />,
            value: dashboardData?.total_data?.swot_schedule,
        },
        {
            key: 2,
            label: "Total Swot",
            icon: <Swot />,
            value: dashboardData?.total_data?.swot,
        },
        {
            key: 3,
            label: "Total Response",
            icon: <Department />,
            value: dashboardData?.total_data?.swotData?.totalResponse == 0 ? "0 %" :
                (
                    (parseInt(dashboardData?.total_data?.swotData?.totalResponse) /
                        parseInt(dashboardData?.total_data?.swotData?.totalEmployee)) *
                    100
                ).toFixed(2) + " %",
        },
    ];



    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            {loader ? (
                <div className="spinner">
                    <Spin />
                </div>
            ) : (
                <div className="mainWrapper">
                    <Card className="card contentCard border-0 pb-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="pageHeader">
                                    <h3>Dashboard</h3>
                                </div>
                            </div>
                            {items?.map((item, index) => (
                                <div className="col-md-4 mb-4" key={index}>
                                    <Card className="dashboardCard">
                                        <div className="innerBody">
                                            <div className="leftSection">
                                                <h5>{item.label}</h5>
                                                <span>{item.value}</span>
                                            </div>
                                            <div className="rightSection">
                                                <div className="iconWrapper">{item.icon}</div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            ))}

                            <div className="col-lg-6 mb-4">
                                <Card className="chartCard">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={SwotSurveyChart(dashboardData?.monthlyData)}
                                    />
                                </Card>
                            </div>
                            <div className="col-lg-6 mb-4">
                                <Card className="chartCard">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={ResponseSurveyChart(dashboardData?.monthlyData)}
                                    />
                                </Card>
                            </div>
                            <div className="col-lg-6">
                                <Card className="chartCard">
                                    <SwotLineChart></SwotLineChart>
                                </Card>
                            </div>
                        </div>
                    </Card>
                </div>
            )}

            <Modal
                title="Company Details"
                open={isModalOpen}
                onCancel={handleCloseModal}
                footer={false}
                className="company-details-modal primaryModal"
                width={900}
            >
                <ComapnyDetails stepNumber={stepNumber} />
            </Modal>
        </>
    );
}
